/**
 * @param {object} params
 * @this sAction
 */
export default function subpanelNewRecord(params) {
    const {paramData, way, prefix, supressClearingPredefinedFields} = params;

    const parent = this.dataGet(prefix);
    if (paramData.module === 'Documents') {
        const data = {
            way: way,
            prefix: way + '/rows',
            rel: {
                module: parent.module,
                relname: paramData.def.getIn(['rel', 'relName']),
                id: parent.id,
            },
        };
        this.processFileInput(data);
    } else {
        const rel = paramData.def.get('rel');
        const params = {
            supressClearingPredefinedFields: supressClearingPredefinedFields ?? false,
            module: paramData.module,
            parentModule: parent.module,
            way: way,
            parentId: parent.id,
            parentName: parent.name,
            rel: rel,
            prefix: prefix,
        };
        this.newSubRecord(params);
    }
}
