/**
 * translateListViewData - translate data from old list view to new list view linear format (CompactListView)
 * @param {Record} data - data from old list view (immutable)
 * @param {string} way - to determine if is list view or subPanel
 * @returns {object} - data for new list view
 * @example
 * translateListViewData(data);
 * // returns {rowsDef: [], rows: []}
 */
export default function translateListViewData(data, way = 'view') {
    const isSubpanel = way.includes('subpanel');
    const returnData = {
        rowsDef: [],
        rows: [],
        module: data.modul,
    };

    if (!data?.rows || !data?.records) {
        return returnData;
    }

    // rowsDef get from data.rows.def to object
    data.rows.forEach((row) => {
        const rowDef = {
            name: row.key,
            label: row.label,
            def: row.def.toJS(),
            disabled: row.disabled,
            module: data.modul,
        };

        // add colors data if exists
        const colors = data?.colors?.get(row.key.toLowerCase());
        if (colors) {
            rowDef.def.colors = colors.toJS();
        }

        returnData.rowsDef[row.key] = rowDef;
    });

    // rows get from data.rows.records (vertical to horizontal)
    const rows = [];
    data.records.forEach((recordId, i) => {
        const values = {};
        // loop through rowsDef and get values from rowDef.records[i]
        data.rows.forEach((rowDef) => {
            values[rowDef.key] = {
                value: rowDef.records.get(i).toJS(),
            };
        });

        if (isSubpanel && typeof recordId === 'object') {
            console.log('translateListViewData returnData.module1: ', returnData.module);

            if (recordId?.module && recordId?.module.length > 0) {
                returnData.module = recordId?.module;
            }
            recordId = recordId?.record;
        }

        rows.push({
            id: recordId,
            values,
        });
    });

    returnData.rows = rows;

    return returnData;
}
