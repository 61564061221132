/**
 * @param {*} data
 * @param {*} content
 */
export default function detailContent(data, content) {
    let panels = data.view.panels;
    if (Array.isArray(panels)) {
    // SS detail vyjimka a mozna i dalsich modulu - neprijde objekt s klicem panelu, ale rovnou jeho obsah - pole
        panels = {
            'default': panels,
        };
    }
    const tabs = [];
    const fields = {};
    let recordName = null;

    let tab = {};
    let rowIndex = 0;

    // TODO DM Dead code, odl sugarView structure for Views
    if (panels) {
        panels.forEachObject((value, key) => {
            const panel = {};

            let tabDef = {newTab: false};
            if (data.view.templateMeta.tabDefs != null) {
                tabDef = data.view.templateMeta.tabDefs[key.toUpperCase()];
            }
            if (tabDef.newTab === true || tabs.length === 0) {
                if (value.length !== 0) {
                    panel.name = '';
                    panel.rows = [];
                    tab = {
                        name: key,
                        panels: [panel],
                    };
                } else {
                    tab = {
                        name: key,
                        panels: [],
                    };
                }

                tabs.push(tab);
                rowIndex = 0;
            } else {
            // tab.rows[rowIndex] = { type: "headerPanel", name: key };
                panel.name = key;
                panel.rows = [];
                tab.panels.push(panel);
                rowIndex = 0;
            }

            value.forEach((el) => {
                if (Array.isArray(el)) {
                    el.forEach((field) => {
                        let name;
                        if (typeof field === 'object') {
                            name = field.name;
                        } else {
                            name = field;
                        }
                        if (name === '') {
                            name = 'empty_row';
                        }

                        if (!panel.rows[rowIndex]) {
                            panel.rows[rowIndex] = {
                                fields: [],
                            };
                        }
                        // _________________________________doplnění definice fieldu
                        let def = data.def[name];
                        if (def == null) {
                            if (name.endsWith('_name')) {
                                const editName = name.substr(0, name.lastIndexOf('_name'));
                                def = data.def[editName];
                            }
                        }
                        if (name === 'duration_hours' && def.type === 'int') {
                            def.type = 'hoursMinutes';
                            def.minutesName = 'duration_minutes';
                            def.minutesValue = data.record['duration_minutes'];
                        }
                        if (name === 'name') {
                            recordName = data.record[name];
                        } else if (name === 'last_name') {
                            recordName =
                            data.record['last_name'] + ' ' + data.record['first_name'];
                        } else if (name === 'document_name') {
                            recordName = data.record[name];
                        } else if (data.record.name) {
                            recordName = data.record.name;
                        } else if (name === 'currency_id') {
                            def.type = 'currency_id';
                        }

                        if (def != null) {
                            if (def.type === 'relate') {
                                def['id_value'] = data.record[def.id_name];
                            }
                            if (def.type === 'parent') {
                                def['parent_type_value'] = data.record['parent_type'];
                                def['id_value'] = data.record['parent_id'];
                            }
                            if (def.type === 'file') {
                                let [fileId, fileName] = (data.record[name] || ''
                                ).split('::');
                                if (!fileName) {
                                    fileName = fileId;
                                    fileId = data.record['id'];
                                }
                                def.file = {
                                    id: fileId,
                                    source: 'sugar',
                                    name: fileName,
                                };
                            }
                            if (def.group != null && def.name.indexOf('street') !== -1) {
                                data.def.forEachObject((fieldDef, key) => {
                                    if (key !== def?.name && fieldDef?.name.indexOf(def?.group) !== -1) {
                                        const value = data.record[key];
                                        fields[key] = {def: fieldDef, value: value};
                                    }
                                });
                                def.type = 'address';
                            }
                            if (def.type === 'acmAddress' || def.type === 'AcmAddress') {
                                let cName = field.name;
                                let customPrefix = '';
                                if (cName.endsWith('_c')) {
                                    customPrefix = '_c';
                                    cName = cName.substring(0, cName.length - 2);
                                }
                                def.item = {
                                    obec: data.record[`${cName}_obec${customPrefix}`],
                                    cast_obce: data.record[`${cName}_cast_obce${customPrefix}`],
                                    kraj: data.record[`${cName}_kraj${customPrefix}`],
                                    okres: data.record[`${cName}_okres${customPrefix}`],
                                    psc: data.record[`${cName}_psc${customPrefix}`],
                                    gpsX: data.record[`${cName}_gpsx${customPrefix}`],
                                    gpsY: data.record[`${cName}_gpsy${customPrefix}`],
                                };
                            }
                        }
                        // _________________________________doplnění definice fieldu END

                        const fieldToStore = {
                            name: name,
                            value: data.record[name],
                            def: def,
                        };

                        fields[name] = {def: def, value: data.record[name]};
                        panel.rows[rowIndex].fields.push(fieldToStore);
                    });
                } else {
                    for (const index in el) {
                        // eslint-disable-next-line no-prototype-builtins
                        if (el.hasOwnProperty(index)) {
                            const field = el[index];

                            if (panel.rows[rowIndex] == null) {
                                panel.rows[rowIndex] = {
                                    fields: [],
                                };
                            }

                            const fieldToStore = {
                                name: field.name,
                                value: data.record[field.name],
                                def: data.def[field.name],
                            };
                            fields[field.name] = {def: data.def[field.name], value: data.record[field.name]};
                            panel.rows[rowIndex].fields.push(fieldToStore);
                        }
                    }
                }
                rowIndex += 1;
            });
        });
    }

    data.def.forEachObject((field, name) => {
        if (!fields[field.name]) {
            const fieldDef = data.def[field.name] ?? {};
            if (field.name === 'duration_hours') {
                fieldDef.minutesValue = data.record['duration_minutes'];
            }

            if (field.name === 'currency_id') {
                fieldDef.type = 'currency_id';
            }

            // nekdy funguje to a nekdy ono, nechapu neresim, NECHAT!
            if (fieldDef.type === 'acmAddress' || fieldDef.type === 'AcmAddress') {
                let cName = field.name;
                let customPrefix = '';
                if (cName.endsWith('_c')) {
                    customPrefix = '_c';
                    cName = cName.substring(0, cName.length - 2);
                }
                fieldDef.item = {
                    obec: data.record[`${cName}_obec${customPrefix}`],
                    cast_obce: data.record[`${cName}_cast_obce${customPrefix}`],
                    kraj: data.record[`${cName}_kraj${customPrefix}`],
                    okres: data.record[`${cName}_okres${customPrefix}`],
                    psc: data.record[`${cName}_psc${customPrefix}`],
                    gpsX: data.record[`${cName}_gpsx${customPrefix}`],
                    gpsY: data.record[`${cName}_gpsy${customPrefix}`],
                };
            }

            if (fieldDef.type === 'relate') {
                fieldDef['id_value'] = data.record[fieldDef.id_name];
            }

            if (fieldDef.type === 'parent') {
                fieldDef['parent_type_value'] = data.record['parent_type'];
                fieldDef['id_value'] = data.record['parent_id'];
            }

            if (fieldDef.type === 'file') {
                let [fileId, fileName] = (data.record[name] || ''
                ).split('::');
                if (!fileName) {
                    fileName = fileId;
                    fileId = data.record['id'];
                }
                fieldDef.file = {
                    id: fileId,
                    source: 'sugar',
                    name: fileName,
                };
            }

            fields[field.name] = {
                def: fieldDef,
                value: data.record[field.name],
            };
        }
    });

    // _______ pokud není vytáhly assigned_user
    // TODO DM Dead code
    if (!fields.assigned_user_name) {
        const def = data.def['assigned_user_name'] || {};
        def['id_name'] = 'assigned_user_id';
        def['id_value'] = data.record['assigned_user_id'];

        fields.assigned_user_name = {
            value: data.record['assigned_user_name'],
            def,
        };
    }
    // _______ pokud není vytáhly assigned_user END

    const admin = this.userPreferences.is_admin;
    data.menu.forEach((button, index) => {
        if (button.admin && +admin !== 1) {
            delete data.menu[index];
        }
    });

    const modulAcl = this.getAccess(data.module);
    if (modulAcl != null && data.acl != null) {
        data.acl.forEachObject((a, k) => {
            modulAcl[k] = a;
        });
    }
    content.acl = modulAcl;

    content.recordName = this.decodeHTMLEntities(recordName ?? data.record.name);
    // For DocumentRevisions module
    if (data.module === 'DocumentRevisions' && !content.recordName) {
        content.recordName = data.record.document_name;
    }
    content.tabs = panels ? tabs : data.view;
    content.actionButtons = data.menu;
    content.timeline = data.timeline;
    content.fields = fields;
    content.dashboard = data.dashboard;
    content.activities = data.activities;
    content.readonly = data.readonly;
    content.table_name = data.table_name;
    if (data.mode) {
        content.mode = data.mode;
    }
    if (data.module === 'Meetings' || data.module === 'Calls') {
        content.invitees = data.invitees;
    }
    // _________ SUBPANELS
    content.subpanels = data.subpanels;
    // _________SUBPANELS END
    content.customData = data.customData;
}
