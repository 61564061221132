/**
 * @param {object} data
 * @param {string} data.module
 * @param {string} data.parentModule
 * @param {string} data.parentId
 * @param {string} data.parentName
 * @param {string} data.prefix
 * @param {any} data.rel
 * @param {object|array} data.relateSimple
 * @this sAction
 */
export default function newSubRecord(data) {
    let {way, module, parentModule, parentId, parentName, prefix, rel, relateSimple} = data;
    if (!module || !parentModule || !parentId || !parentName || !prefix) {
        console.error('Invalid data');

        return;
    }
    if(data.supressClearingPredefinedFields !== true){
        this.detailPredefinedFields = [];
    }
    const activitiesModules = ['Calls', 'Meetings', 'Tasks', 'Notes'];

    if (activitiesModules.includes(module)) {
        if (parentModule === 'Contacts') {
            const customData = {
                invitees: {
                    relatedInv: {},
                },
            };

            customData.invitees.relatedInv = {Users: [], Contacts: [], Leads: []};
            const contact = {
                email: this.dataGet(`${prefix}/fields/email1/value`),
                id: parentId,
                name: parentName,
                phone_mobile: this.dataGet(`${prefix}/fields/phone_mobile/value`),
                phone_work: this.dataGet(`${prefix}/fields/phone_work/value`),
            };
            customData.invitees.relatedInv.Contacts.push(contact);
            this.detailPredefinedFields.push({type: 'customData', value: customData});

            this.detailPredefinedFields.push({
                type: 'relate_simple',
                fieldName: 'contact_name',
                name: 'contact_id',
                value: {'id': parentId, 'name': parentName},
            });

            const parentAccount = this.dataGet(`${prefix}/fields/account_name`);
            if (parentAccount) {
                const accountId = parentAccount.def.get('id_value');
                const accountName = parentAccount.get('value');
                if (accountId) {
                    parentName = accountName;
                    parentId = accountId;
                    parentModule = 'Accounts';
                }
            }
        }

        this.detailPredefinedFields.push({
            type: 'parent',
            name: 'parent_name',
            parentName,
            parentId,
            parentModule,
        });
    } else if (relateSimple) {
        this.detailPredefinedFields.push({
            type: 'relate_simple',
            ...relateSimple,
        });
    } else {
        if (!rel) {
            console.warn('nejsou zadány inforamce o vazbě');

            return;
        }
        this.detailPredefinedFields.push({
            type: 'relate',
            module: parentModule,
            id: parentId,
            value: parentName,
            rel,
        });
    }

    this.popupDetail({
        module,
        record: '',
        saveCallback: () => {
            const params = {subpanelAction: 'new_relate'};
            if (prefix === 'rightPanel/data') {
                this.reloadActivitiesPanel(prefix);
            } else {
                this.reloadSubpanelByWay(prefix, module, params);
                this.openSubpanel(way, prefix);
            }
        },
        exitCallback: () => {

        },
    });
}
