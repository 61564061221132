/**
 *
 * @param data
 */
export default function listContent(data) {
    const content = {};
    const rows = data.rows;
    const records = data.records;
    const def = data.def;
    content.listViewTree = data.listViewTree;
    content.rows = rows;
    content.columns = data.columns;
    content.records = records;
    content.def = def;
    content.orderBy = null;
    content.asc = null;
    content.menu = data.menu;
    content.offset = data.current_offset;
    content.rowCount = data.row_count;
    content.limit = data.next_offset - data.current_offset;
    content.prefix = data.prefix;
    content.category = data.category;
    content.reportId = data.reportId;

    if (data.timeline != undefined) {
        const timeline = data.timeline;
        const timelinefield = timeline.field;
        content.colors = {};
        content.colors[timelinefield] = timeline.colors;
    }

    if (data.alterName) {
        content.columns.forEach((column) => {
            for (const value of Object.values(data.alterName)) {
                if (value.field === column.field && value.module === column.module) {
                    column.alterName = value.vname;
                }
            }
        });
    }

    if (data.saved_search !== undefined) {
        content.savedSearch = data.saved_search.data;
    } else {
        content.savedSearch = [];
    }
    return content;
}
